//import vue router
import { createRouter, createWebHistory } from 'vue-router'

//define a routes
const routes = [
    {
        path: '/',
        name: 'login-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/LoginPage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },
    
    {
        path: '/home',
        name: 'home-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/HomePage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },

    {
        path: '/pengajuan',
        name: 'pengajuan-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/PengajuanPage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },

    {
        path: '/users',
        name: 'user-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/UserPage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },

    {
        path: '/dinas',
        name: 'dinas-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/DinasPage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },

    {
        path: '/signup',
        name: 'signup-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/SignupPage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },

    {
        path: '/syarat',
        name: 'syarat-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/SyaratPage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },

    {
        path: '/profile',
        name: 'profile-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/ProfilePage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },

    {
        path: '/archive',
        name: 'archive-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/ArchivePage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },

    {
        path: '/editp/:id',
        name: 'editp-page',
        component: () => import( /* webpackChunkName: "post.index" */ '@/views/EditpPage.vue'),
        meta: {
            progress: {
                func: [
                { call: "color", modifier: "temp", argument: "#ffb000" },
                { call: "fail", modifier: "temp", argument: "#6e0000" },
                { call: "location", modifier: "temp", argument: "top" },
                {
                    call: "transition",
                    modifier: "temp",
                    argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
                },
                ],
            },
        },
    },
]

//create router
const router = createRouter({
    history: createWebHistory(),
    routes  // config routes
})

export default router