<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark ">
        <div class="container">
        <!-- <a v-if="islogo" class="navbar-brand" href="#">
            <img :src="server + '/storage/' + user.foto" style="max-height:30px; max-width:50px" class="image" alt="Profile Picture"/> DINAS
        </a> -->
        <a class="navbar-brand" href="#">
            <img :src="'/logo.png'" style="max-height:30px; max-width:50px" class="image" alt="Profile Picture"/> Dinas
        </a>
        <!-- <a v-else class="navbar-brand" href="#">
            <i class="fab fa-uikit"></i> DINAS
        </a> -->
            <button class="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div class="navbar-nav mr-auto">
                    <!-- Multi -->
                    <a v-on:click="goPenelitian" class="nav-link" href="#">Penelitian</a>

                    <!-- Peneliti -->
                    <template v-if="ispeneliti">
                        <a v-on:click="goPengajuan" class="nav-link" href="#">Pengajuan</a>
                        <a v-on:click="tosyarat" class="nav-link" href="#">Syarat & Ketentuan</a>
                    </template>

                    <!-- Dinas -->
                    <template v-if="isdinas">
                        <a v-on:click='toarchive' class="nav-link" href="#">Arsip</a>
                    </template>

                    <!-- Admin -->
                    <template v-if="isadmin">
                        <a v-on:click="goDinas" class="nav-link" href="#">Dinas</a>
                        <a v-on:click="goUser" class="nav-link" href="#">Pengguna</a>
                    </template>
                </div>
            </div>
            <div class="div navbar-nav">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{ this.user.nama }}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a v-on:click="toprofile" class="dropdown-item" href="#">Profile</a></li>
                        <li><hr class="dropdown-divider"></li>
                        <li><a v-on:click="modalLogout" class="dropdown-item" href="#">Log Out</a></li>
                    </ul>
                </li>
            </div>
        </div>

        <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-dark text-white">
                        <h5 class="modal-title" id="exampleModalLabel">Apakah anda yakin?</h5>
                        <button v-on:click='modalDismiss' type="button" class="btn-close btn-close-white" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">Pilih "Logout" di bawah ini untuk menyelesaikan sesi anda.</div>
                    <div class="modal-footer">
                        <button v-on:click='modalDismiss' class="btn btn-secondary" type="button" data-dismiss="modal">Batal</button>
                        <a  v-on:click='logout' class="btn btn-primary" href="#">Logout</a>
                    </div>
                </div>
            </div>
        </div>

    </nav>
</template>

<script>
import { server } from '@/variables'
import axios from 'axios'
export default {
    data() {
        return {
            token: '',
            loading: false,
            server: server,
            user: {}
        }
    },

    computed: {
        isInside() {
            return this.$route.path !== '/'
        },
        hasToken() {
            return localStorage.getItem("token") !== null
        },
        islogo() {
            return (this.user.foto != null && this.user.role_id == '2')
        },
        isdinas() {
            return this.user.role_id == '2'
        },
        isadmin() {
            return this.user.role_id == '1'
        },
        ispeneliti() {
            return this.user.role_id == '3'
        },
    },

    methods: {
        modalDismiss() {
            $(document).ready(function() {
                $('.modal').modal('hide');
            })
        },
        modalLogout() {
            $(document).ready(function() {
                $('#logoutModal').modal('show');
            })
        },
        logout() {
            $('#logoutModal').modal('hide');
            this.loading = true
            axios.get(this.server + '/api/logout', {headers: {'Authorization': 'Bearer '+this.token}}).then(response => {
                if (response.data.success) {
                    localStorage.removeItem('token')
                    this.$router.push({ name: 'login-page' });
                }
            }).finally(() => {
                this.loading = false
            })
        },
        loadUser() {
            this.loading = true
            this.token = localStorage.getItem('token')
            axios.get(this.server + '/api/whoami', {headers: {'Authorization': 'Bearer '+this.token}}).then(response => {
                this.user = response.data
            }).finally(() => {
                this.loading = false
                this.$notify({ type: "success", title: "Halo " + this.user.nama, text: "Selamat datang kembali" });
            })
        },
        goPenelitian() {
            this.$router.push({
                name: 'home-page',
            });
        },
        goPengajuan() {
            this.$router.push({
                name: 'pengajuan-page',
            });
        },
        goDinas() {
            this.$router.push({
                name: 'dinas-page',
            });
        },
        goUser() {
            this.$router.push({
                name: 'user-page',
            });
        },
        tosyarat(){
            this.loading = true
            this.$router.push({ name: 'syarat-page' });
        },
        toprofile(){
            this.loading = true
            this.$router.push({ name: 'profile-page' });
        },
        toarchive(){
            this.loading = true
            this.$router.push({ name: 'archive-page' });
        },
    },

    created() {
        if (!this.hasToken && this.isInside) {
            this.$router.push({
                name: 'login-page',
            });
        }
        this.token = localStorage.getItem('token')
        this.loadUser()
    },
}
</script>