import { createApp } from 'vue'
import App from './App.vue'
import Notifications from '@kyvg/vue3-notification'

//import router
import router from './router'

import VueProgressBar from '@aacassandra/vue3-progressbar'

const options = {
    color: "#bffaf3",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "left",
    inverse: false,
}

//import Bootstrap, Popper, jQuery
import 'bootstrap/dist/css/bootstrap.css'
import 'jquery/dist/jquery.min'
import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min'

const app = createApp(App)

app.use(Notifications)
app.use(VueProgressBar, options)

//use vue router
app.use(router)

app.mount('#app')